import { Button, Input, TextInputType } from '@finn/atoms';
import { LegalText } from '@finn/auto-ui/modules/legal/constants';
import { getLegalAllText } from '@finn/auto-ui/modules/legal/utils';
import { RECAPTCHA_SITE_KEY } from '@finn/ua-constants';
import { interactionTrackingEvent, TrackingEventName } from '@finn/ua-tracking';
import { DatePicker, Recaptcha, recaptchaCall } from '@finn/ui-components';
import { PrequalifyFormTypeCosmicData } from '@finn/ui-cosmic';
import { parseToHtml } from '@finn/ui-utils';
import { Box, FormControlLabel, makeStyles } from '@material-ui/core';
import cn from 'classnames';
import { get } from 'lodash';
import Script from 'next/script';
import React, { useCallback, useContext, useRef } from 'react';
import { Controller, FormProvider, UseFormReturn } from 'react-hook-form';
import { useIntl } from 'react-intl';

import Checkbox from '~/components/FormElements/Checkbox';
import DateInfoContext from '~/contexts/DateInfo';
import { callWebhookFromId } from '~/services/webhook';
import { UIBaseCosmicObject } from '~/types/UIModules';
import { getInputPlaceholder } from '~/utils/cosmic';

import { Fields, FormInputs, initialValues } from './config';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'grid',
    gridGap: theme.spacing(3),
    [theme.breakpoints.up('sm')]: {
      gridTemplateColumns: '1fr 1fr',
    },
  },
  fillGridWidth: {
    gridColumn: 'span 1',
    [theme.breakpoints.up('sm')]: {
      gridColumn: 'span 2',
    },
  },
  error: {
    color: 'red',
  },
  consent: {
    alignItems: 'start',
    gap: theme.spacing(1.5),
    '& span': {
      padding: 0,
    },
    '& input': {
      position: 'relative',
    },
  },
  checkbox: {
    padding: 0,
  },
}));

type IProps = {
  cosmicData: UIBaseCosmicObject<PrequalifyFormTypeCosmicData>;
  methods: UseFormReturn<FormInputs>;
};

const FormWrapper: React.FunctionComponent<IProps> = ({
  cosmicData,
  methods,
}) => {
  const classes = useStyles();
  const {
    handleSubmit,
    reset,
    control,
    getValues,
    formState: { isSubmitting },
  } = methods;
  const didTrackFieldEnter = useRef(new Set()).current;
  const { dateLabel } = useContext(DateInfoContext);
  const i18n = useIntl();
  const newsletterCheckboxText = getLegalAllText(
    i18n.locale,
    LegalText.NEWSLETTER_CHECKBOX_TEXT
  );

  const trackFormFieldEnter = (fieldName: string) => {
    if (!didTrackFieldEnter.has(fieldName)) {
      interactionTrackingEvent(TrackingEventName.FORM_FIELD_ENTERED, {
        name: fieldName,
        location: 'Prequalify',
      });
      didTrackFieldEnter.add(fieldName);
    }
  };

  const trackFormFieldFocus = (fieldName: string) => {
    interactionTrackingEvent(TrackingEventName.FORM_FIELD_FOCUSED, {
      name: fieldName,
      location: 'Prequalify',
    });
  };

  const trackCheckboxEvent = (name: string, action: string) => {
    interactionTrackingEvent(TrackingEventName.CHECKBOX_CLICKED, {
      name,
      action,
      location: 'Prequalify',
    });
  };

  const handleCTAClick = () => {
    interactionTrackingEvent(TrackingEventName.CTA_CLICKED, {
      name: 'Prequalify',
      location: 'Prequalify',
    });
  };

  const onSubmit = useCallback(async () => {
    const values = getValues();
    const token = await recaptchaCall();
    handleCTAClick();
    try {
      const { status } = await callWebhookFromId('PREQUALIFY', {
        ...values,
        token,
      });
      if (status === 200) {
        reset(initialValues);
      }
    } catch (error) {
      console.log(error);
    }
  }, [getValues, reset]);

  const {
    FirstName,
    LastName,
    PhoneNumber,
    Email,
    Street,
    ZipCode,
    DateOfBirth,
    PrequalifyConsent,
    NewsletterConsent,
  } = Fields;

  const createControlledInput = (
    fieldName,
    propertyName,
    type: TextInputType
  ) => {
    return (
      <Controller
        name={fieldName}
        control={control}
        render={({ field, fieldState: { error } }) => (
          <Box>
            <Input
              {...field}
              ref={undefined}
              type={type}
              variant={'filled'}
              label={getInputPlaceholder(cosmicData, propertyName)}
              errorMessage={
                !!error &&
                i18n.formatMessage({
                  id: error.message,
                })
              }
              fullWidth
              onFocus={() => trackFormFieldFocus(fieldName)}
              onChange={(e) => {
                field.onChange(e);
                trackFormFieldEnter(fieldName);
              }}
            />
          </Box>
        )}
      />
    );
  };

  return (
    <>
      <Script
        src={`https://www.google.com/recaptcha/api.js?render=${RECAPTCHA_SITE_KEY}`}
      />
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box className={classes.wrapper}>
            {createControlledInput(FirstName, 'first_name', 'text')}
            {createControlledInput(LastName, 'last_name', 'text')}
            {createControlledInput(PhoneNumber, 'phone_number', 'tel')}
            {createControlledInput(Email, 'email_address', 'email')}
            {createControlledInput(Street, 'street_address', 'text')}
            {createControlledInput(ZipCode, 'zip_code', 'text')}
            <Box>
              <DatePicker
                name={DateOfBirth}
                withInput
                disableFuture
                label={getInputPlaceholder(cosmicData, 'date_of_birth')}
                fullWidth
                showKeyboardIcon={false}
                readOnly
                placeholder={dateLabel}
              />
            </Box>
            <Box className={classes.fillGridWidth}>
              <FormControlLabel
                className={classes.consent}
                classes={{
                  label:
                    '!body-14-light [&_a]:!link-16 [&_a:after]:!inline [&_a:before]:!inline',
                }}
                control={
                  <Controller
                    name={PrequalifyConsent}
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                      <Checkbox
                        {...field}
                        ref={undefined}
                        error={!!error}
                        checked={field.value}
                        className={cn(classes.checkbox, {
                          [classes.error]: !!error,
                        })}
                        onChange={(event) => {
                          field.onChange(event);
                          trackCheckboxEvent(
                            PrequalifyConsent,
                            event.target.checked ? 'checked' : 'unchecked'
                          );
                        }}
                      />
                    )}
                  />
                }
                label={parseToHtml(get(cosmicData, 'metadata.checkbox_label'))}
              />
            </Box>
            <Box className={classes.fillGridWidth}>
              <FormControlLabel
                className={classes.consent}
                classes={{
                  label:
                    '!body-14-light [&_a]:!link-16 [&_a:after]:!inline [&_a:before]:!inline',
                }}
                control={
                  <Controller
                    name={NewsletterConsent}
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                      <Checkbox
                        {...field}
                        ref={undefined}
                        error={!!error}
                        checked={field.value}
                        className={cn(classes.checkbox, {
                          [classes.error]: !!error,
                        })}
                        onChange={(event) => {
                          field.onChange(event);
                          trackCheckboxEvent(
                            NewsletterConsent,
                            event.target.checked ? 'checked' : 'unchecked'
                          );
                        }}
                      />
                    )}
                  />
                }
                label={parseToHtml(newsletterCheckboxText)}
              />
            </Box>
            <Box>
              <Button
                type="submit"
                loading={isSubmitting}
                color="primary"
                label="Pre-Qualify now"
                size="large"
                fullWidth
              />
            </Box>
          </Box>
        </form>
        <Recaptcha />
      </FormProvider>
    </>
  );
};
export default FormWrapper;
