import { CookieKeys, getClientCookie } from '@finn/ui-utils';
import axios from 'axios';

import config from '~/config';

export type IntegromatPayload = {
  salutation: string;
  first_name: string;
  last_name: string;
  company_name: string;
  mail: string;
  phone_number: string;
  message?: string;
  number_of_cars: string;
  b2b_multiplier: string;
  b2b_source?: string;
  referrer_code?: string;
  b2b_url?: string;
  gdpr?: boolean;
};

// We trigger an Integromat scenario to create company/contact/deal and put them in B2B pipeline for DE:
// https://finn.integromat.cloud/6/scenarios/329/edit
// For US:
// https://finn.integromat.cloud/58/scenarios/10114/edit
export const submitB2bContactForm = async (
  payload: IntegromatPayload,
  region: string
) => {
  const gclCookie = getClientCookie(CookieKeys.GCLID);
  const { data, status } = await axios.get(
    region === 'DE'
      ? config.INTEGROMAT_B2B_FORM_SUBMISSION_URL_DE
      : config.INTEGROMAT_B2B_FORM_SUBMISSION_URL_US,
    {
      params: { ...payload, gclCookie },
    }
  );

  return { data, status };
};
