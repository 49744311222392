import { B2BFormTypeCosmicData } from '@finn/ui-cosmic';
import {
  triggerB2BFormSubmissionEvent,
  triggerB2BLeadGenFormSubmissionEvent,
} from '@finn/ui-modules';
import {
  CookieKeys,
  formatPhoneNumber,
  getB2BFormSource,
  getClientCookie,
  getSession,
  identifyAnonymousUserEvent,
  identifyEvent,
} from '@finn/ui-utils';
import { Snackbar } from '@material-ui/core';
import { Formik, FormikHelpers } from 'formik';
import { useRouter } from 'next/router';
import React, { useState } from 'react';

import { useCurrentLocale } from '~/hooks/useCurrentLocale';
import { submitB2bContactForm as integromatSubmit } from '~/services/b2b';
import { UIBaseCosmicObject } from '~/types/UIModules';

import Alert from '../Alert';
import Form from '../Form';
import {
  ContactValues,
  createValidationSchema,
  initialValues,
  toIntegromate,
} from './config';

type Props = {
  cosmicData: UIBaseCosmicObject<B2BFormTypeCosmicData>;
};

const FormikWrapper: React.FunctionComponent<Props> = ({ cosmicData }) => {
  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState(null);
  const { region } = useCurrentLocale();
  const router = useRouter();
  const isB2BPage = router.asPath?.includes('/b2b');

  const handleSubmit = async (
    values: ContactValues,
    formikHelpers: FormikHelpers<ContactValues>
  ) => {
    const contactData = {
      ...values,
      phoneNumber: formatPhoneNumber(values.phoneNumber, region),
      b2bMultiplier: getClientCookie(CookieKeys.B2B_MULTIPLIER) ?? '',
      utmSource: getB2BFormSource('B2B Form'),
      referrerCode: getClientCookie(CookieKeys.B2B_REFERRER_CODE) ?? '',
      b2bUrl: router.asPath,
    };
    setLoading(true);
    const integromatResponse = await integromatSubmit(
      toIntegromate(contactData),
      region
    );
    setLoading(false);
    if (integromatResponse.status === 200) {
      if (isB2BPage) {
        triggerB2BFormSubmissionEvent(values.fleetSize, values.email);
      } else {
        triggerB2BLeadGenFormSubmissionEvent(values.fleetSize, values.email);
      }
      const session = await getSession();
      if (session && session.user.hs_contact_id) {
        identifyEvent(String(session.user.hs_contact_id), {
          companyName: values.company,
          firstName: values.firstName,
          lastName: values.lastName,
          loggedIn: !!session,
        });
      } else {
        identifyAnonymousUserEvent({
          companyName: values.company,
          firstName: values.firstName,
          lastName: values.lastName,
          loggedIn: !!session,
        });
      }
      setSuccessMessage(cosmicData.metadata.submit_success_message);
      formikHelpers.resetForm();
      setTimeout(() => {
        setSuccessMessage(null);
      }, 2000);
    }
  };

  const validationSchema = createValidationSchema({ region });

  return (
    <Formik<ContactValues>
      initialValues={initialValues}
      validationSchema={validationSchema}
      validateOnChange={false}
      enableReinitialize
      onSubmit={handleSubmit}
    >
      {(formik) => {
        return (
          <>
            <Form
              isB2B
              formik={formik}
              loading={loading}
              cosmicData={cosmicData}
            />
            <Snackbar
              open={Boolean(successMessage)}
              autoHideDuration={2000}
              anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
              <Alert severity="success">{successMessage}</Alert>
            </Snackbar>
          </>
        );
      }}
    </Formik>
  );
};
export default FormikWrapper;
