import { Container } from '@finn/atoms';
import { B2BContactFormCosmicMetadata } from '@finn/ui-cosmic';
import { cn } from '@finn/ui-utils';
import { Box, makeStyles } from '@material-ui/core';
import React from 'react';

import { parseToHtml } from '~/utils/html';

import FormikWrapper from './FormikWrapper';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'grid',
    gridGap: theme.spacing(3),
    gridTemplateColumns: '1fr',
    [theme.breakpoints.up('md')]: {
      gridTemplateColumns: '1fr 1fr',
    },
  },
}));

interface IProps {
  data: B2BContactFormCosmicMetadata;
}

// TODO share this between dynamic components
export const titleHeading = {
  h1: 'mobile-t1-semibold md:web-t1-semibold',
  h2: 'mobile-t2-semibold md:web-t2-semibold',
  h3: 'mobile-t3-semibold md:web-t3-semibold',
  h4: 'mobile-t4-semibold md:web-t4-semibold',
  h5: 'global-t5-semibold',
  h6: 'global-t6-semibold',
};

export const B2BContactForm: React.FunctionComponent<IProps> = ({ data }) => {
  const classes = useStyles();
  const titleClassName = titleHeading[data.header_size?.key] || titleHeading.h2;
  const TitleEl =
    (data.header_size?.key as keyof JSX.IntrinsicElements) || 'h2';

  return (
    <Container>
      <Box id="b2b-contact-form" className={classes.wrapper}>
        <Box>
          <TitleEl className={cn(titleClassName, 'mb-14')}>
            {parseToHtml(data.title)}
          </TitleEl>
          <p className="body-16-light">{parseToHtml(data.description)}</p>
        </Box>
        <Box>
          <FormikWrapper cosmicData={data.form_type} />
        </Box>
      </Box>
    </Container>
  );
};

export default B2BContactForm;
